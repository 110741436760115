
// font-family: 'Roboto', sans-serif;
*{
  font-family: 'Roboto', sans-serif;
}

.eventsContainer{
  // position: relative;
  width: 100%;
  min-height: 100vh;
  flex-direction: column;
  display: flex;
  // align-items: center;
  justify-content: center;
  background: rgb(11,10,17);
  background: var(--background-color);
  background-size: contain;
  border: 3px var(--border-color) solid;
}

.noEventsText{
  color: var(--text-color);
  font-size: 22px;
  font-weight: bold;
  margin: 20px 0;
}

.singleEventWrapper{
  width: 90%;
  min-width: 350px;
  max-width: 500px;
  display: flex;
  flex-direction: column;
  padding: 10px;
  background: rgb(11,10,17);
  background: var(--background-color);
  border: 3px var(--border-color) solid;
  margin-top: 20px;
  border-radius: 20px;
  text-align: right;
  color: var(--color);

  div{
    display: flex;
    align-items: center;
    font-weight: bold;
    button{
      width: 100%;
      height: 100%;
      font-weight: bold;
      font-size: 22px;
      cursor: pointer;
    }

  }
  div:not(:last-child){
    border-bottom: 1px solid var(--border-color);;
    justify-content: space-between;
    p{
      margin: 5px 2px;
    }
  }
  div:last-child{
    margin: 15px 0 5px 0;
    button{
      padding: 10px;
      border-radius: 10px;
      border: .1px black solid;
      cursor: pointer;
    }
  }
}

.presenterHeader{
  text-align: center;
  margin-top: 50px;
  // border-bottom: red 1px solid;
  // position: fixed;
  // top: 0;
}

// background: rgb(18,95,194);
// background: linear-gradient(90deg, rgba(18,95,194,1) 15%, rgba(25,119,125,1) 31%, rgba(33,147,42,1) 50%, rgba(90,145,39,1) 67%, rgba(140,144,37,1) 82%, rgba(175,177,58,1) 88%, rgba(232,233,8,1) 95%);

.audienceHeaderLogo{
  margin-top: 30px;
  width: 180px;

  img{
    width: 100%;
  }
}

.centeredScreen{
  text-align: center;
  margin: 25px;

  h1{
    color: var(--header-color);
    font-weight:bold;
    font-size: 31px;
    margin-top: 0;
  }
  p{
    color: var(--subheader-color);
    font-size: 22px;
    margin:0;
    font-weight: bold;
  }
}

.audienceFooter{
  width: 165px;
  margin: 15px 0;

  img{
    width: 100%;
  }
}

.pollingButton,
.attendeeButton{
  padding: 10px;
  border-radius: 10px;
  border: .1px black solid;
  font-weight: bold;
  font-size: 22px;
  cursor: pointer;
}

.pollingButton{
  width: 95%;
  margin: 10px 0;
  background-color: var(--button-color);
  border: var(--border-color) 1px solid;
  color: var(--text-color);
  text-transform: uppercase;
  height: 7vh;
  font-size: 15px;
}

.pollingImg{
  border: var(--border-color) 2px solid;
  color: var(--text-color);
  margin: 10px 15px;
  height: 18vh;
  width: 18vh;
}

.pollingImgButton{
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.pollingButtonDefault:hover{
  cursor: pointer;
}

.pollingHeader{
  margin: 0 35px;
}
.pollingBody{
  margin-top: 20px;
}